.hero {
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  padding-left: 36px;
  padding-right: 27px;
  .join-container {
    display: none;
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titles {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-width: 580px;
      .offline {
        margin: 0;
        display: inline-flex;
        padding: 4px 4px 4px 12px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        border-radius: 8px;
        background: #653cf4;
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        line-height: 100%;
        span {
          display: flex;
          padding: 4.875px 9.75px;
          justify-content: center;
          align-items: center;
          gap: 12.188px;
          border-radius: 8px;
          background: #fff;
          color: #653cf4;
          font-size: 20px;
          font-weight: 500;
          line-height: 140%;
        }
      }
      h1 {
        max-width: 662px;
        margin: 0;
        padding: 0;
        padding-top: 6px;
        color: #030408;
        font-size: 60px;
        font-weight: 500;
        line-height: 112%;
      }
      .study {
        max-width: 511px;
        margin: 0;
        padding: 0;
        padding-top: 20px;
        color: #030408;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        opacity: 0.6;
      }
      a {
        margin-top: 32px;
        text-decoration: none;
        display: flex;
        padding: 12px 12px 12px 24px;
        align-items: center;
        gap: 24px;
        transition: transform 0.3s ease;
        border-radius: 28px;
        background: #030408;
        color: #fff;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px;
        .arrow {
          width: 40px;
          height: 40px;
          background-image: url('./../img/arrow.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      }
      a:hover {
        transform: scale(1.1);
      }
    }
    .lecture {
      display: flex;
      box-sizing: border-box;
      width: 558px;
      padding: 27px 27px 27px 27px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 28px;
      border-radius: 24px;
      background: #653cf4;
      img {
        width: 224px;
        height: 82px;
      }
      h3 {
        margin: 0;
        color: #fff;
        font-size: 30.123px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
      .date {
        margin: 0;
        color: #fff;
        font-size: 45px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
      }
      .city {
        margin: 0;
        display: flex;
        padding: 9.178px 18.356px;
        justify-content: center;
        align-items: center;
        gap: 22.945px;
        border-radius: 18.356px;
        background: #fff;
        color: #653cf4;
        font-size: 22.592px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
      }
    }
    .join {
      display: none;
    }
  }
  .question {
    .p-mob {
      display: none;
    }
  }
  .speakers {
    display: none;
  }
}

@media (max-width: 1170px) {
  .hero {
    padding-top: 120px;
    padding-left: 36px;
    padding-right: 27px;
    .info {
      .titles {
        .offline {
          margin: 0;
          display: inline-flex;
          padding: 4px 4px 4px 12px;
          justify-content: center;
          align-items: center;
          gap: 12px;
          border-radius: 8px;
          background: #653cf4;
          color: #fff;
          font-size: 20px;
          font-weight: 500;
          line-height: 100%;
          span {
            display: flex;
            padding: 4.875px 9.75px;
            justify-content: center;
            align-items: center;
            gap: 12.188px;
            border-radius: 8px;
            background: #fff;
            color: #653cf4;
            font-size: 20px;
            font-weight: 500;
            line-height: 140%;
          }
        }
        h1 {
          max-width: 662px;
          margin: 0;
          padding: 0;
          padding-top: 6px;
          color: #030408;
          font-size: 60px;
          font-weight: 500;
          line-height: 112%;
        }
        .study {
          max-width: 511px;
          margin: 0;
          padding: 0;
          padding-top: 20px;
          color: #030408;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          opacity: 0.6;
        }
        a {
          margin-top: 32px;
          text-decoration: none;
          display: flex;
          padding: 12px 12px 12px 24px;
          align-items: center;
          gap: 24px;
          transition: transform 0.3s ease;
          border-radius: 28px;
          background: #030408;
          color: #fff;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 36px;
          .arrow {
            width: 40px;
            height: 40px;
            background-image: url('./../img/arrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          }
        }
        a:hover {
          transform: scale(1.1);
        }
      }
      .lecture {
        display: flex;
        max-width: 400px;
        padding: 14.625px 0px 14.625px 14.625px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 15px;
        border-radius: 24px;
        img {
          width: 119px;
          height: 43px;
        }
        h3 {
          font-size: 16px;
        }
        .date {
          font-size: 24px;
        }
        .city {
          color: #653cf4;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          display: flex;
          padding: 4.875px 9.75px;
          justify-content: center;
          align-items: center;
          gap: 12.188px;
          border-radius: 9.75px;
          background: #fff;
        }
      }
    }
    .question {
      .p-mob {
        display: none;
      }
    }
    .speakers {
      display: none;
    }
  }
}

@media (max-width: 922px) {
  .hero {
    padding-top: 36px;
    padding-left: 16px;
    padding-right: 16px;
    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .titles {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-width: 270px;
        .offline {
          padding: 4px 4px 4px 12px;
          gap: 12px;
          border-radius: 8px;
          font-size: 16px;
          span {
            padding: 4.875px 9.75px;
            gap: 0;
            border-radius: 8px;
            font-size: 16px;
          }
        }
        h1 {
          max-width: 292px;
          padding-top: 14px;
          font-size: 36px;
          line-height: 100%;
        }
        .study {
          max-width: 316px;
          padding-top: 14px;
          font-size: 16px;
        }
        a {
          display: none;
        }
      }
      .lecture {
        display: flex;
        width: 100%;
        margin-top: 16px;
        padding: 14.625px 0px 14.625px 14.625px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 15px;
        border-radius: 24px;
        img {
          width: 119px;
          height: 43px;
        }
        h3 {
          font-size: 16px;
        }
        .date {
          font-size: 24px;
        }
        .city {
          color: #653cf4;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          display: flex;
          padding: 4.875px 9.75px;
          justify-content: center;
          align-items: center;
          gap: 12.188px;
          border-radius: 9.75px;
          background: #fff;
        }
      }
    }
    .join-container {
      display: block;
      padding-right: 20px;
      max-width: 380px;
    }
    .join {
      display: flex;
      margin-top: 19px;
      width: 100%;
      margin-right: 16px;
      padding: 4px 4px 4px 19px;
      justify-content: space-between;
      align-items: center;
      border-radius: 20px;
      background: #030408;
      color: #fff;
      text-decoration: none;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      .arrow {
        width: 37px;
        height: 37px;
        background-image: url('./../img/arrow.svg');
        background-repeat: no-repeat;
        block-size: cover;
      }
    }
    .question {
      .p-mob {
        display: block;
        margin-top: 40px;
        width: 360.75px;
        color: #030408;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        span {
          font-weight: 500;
        }
      }
    }
    .speakers {
      display: none;
    }
  }
}

@media (max-width: 390px) {
  .hero {
    .speakers {
      display: flex;
      flex-direction: column;
      gap: 16px;
      h3 {
        margin: 0;
        margin-top: 46px;
        color: #030408;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 112%;
      }
      .row {
        display: flex;
        gap: 4px;

        .speaker {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: auto;
          img {
            width: 100%;
            height: auto;
          }
          .name {
            margin: 0;
            margin-top: 8px;
            margin-left: 8px;
            color: #030408;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 112%;
          }
          .position {
            margin: 0;
            margin-top: 4px;
            margin-left: 8px;
            color: #030408;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 112%; /* 11.2px */
            opacity: 0.6;
          }
        }
      }
    }
    .question {
      .p-mob {
        display: block;
        margin-top: 40px;
        width: 290.75px;
        color: #030408;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        span {
          font-weight: 500;
        }
      }
    }
  }
}

@media (max-width: 390px) {
  .hero {
    .info {
      .titles {
        .offline {
          display: inline-flex;
          padding: 4px 4px 4px 8px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          background: #653cf4;
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          span {
            display: flex;
            padding: 4px 8px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: #fff;
            color: #653cf4;

            font-family: Ubuntu;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
          }
        }
      }
    }
  }
}
