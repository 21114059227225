.advantages-desk {
  margin-top: 76px;
  padding-right: 35px;
  padding-left: 35px;
  h2 {
    text-align: center;
    font-size: 52px;
    line-height: 52px;
    color: #030408;
  }

  .cards {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 40px;
    padding-left: 20px;
    .card {
      max-width: 327px;
      border-right: 1px solid #030408;
      border-radius: 20px;
      padding: 10px 20px 10px 0px;
      display: flex;
      flex-direction: column;
      min-height: 196px;
      position: relative;
      .top {
        color: #030408;
        font-size: 20px;
        line-height: 24px;
        max-width: 287px;
        position: absolute;
        padding-right: 20px;
      }
      .bottom {
        margin-top: 120px;
        color: #030408;
        font-size: 16px;
        line-height: 24px;
        max-width: 287px;
      }
    }
  }
}
.advantages-mob {
  display: none;
}

@media (max-width: 996px) {
  .nowrap {
    text-wrap: wrap;
  }
}

@media (max-width: 800px) {
  .advantages-desk {
    display: none;
  }
  .advantages-mob {
    display: block;
    margin-top: 48px;
    padding: 0px 16px 0px 16px;

    h2 {
      margin: 0;
      font-size: 36px;
      line-height: 36px;
      color: #030408;
      padding-bottom: 28px;
    }
    h2:after {
      content: ':';
    }
    .swiper {
      width: 100%;
      position: relative;
      .swiper-slide {
        .card {
          border: 1px solid rgba(217, 217, 217, 1);
          border-radius: 26px;
          padding: 0px 18px 10px 18px;
          display: flex;
          flex-direction: column;
          height: 184px;
          .top {
            color: #030408;
            font-size: 20px;
            line-height: 24px;
          }
          .bottom {
            color: #030408;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      .swiper-pagination {
        position: absolute;
        top: 162px;
        left: -34%;
      }

      .swiper-pagination-bullet {
        background-color: transparent;
        border: 2px solid black;
      }

      .swiper-pagination-bullet-active {
        background-color: black;
        border: 2px solid black;
      }
    }
  }
}

@media (max-width: 390px) {
  .advantages-mob {
    // display: block;
    margin-top: 36px;
    padding: 0px 12px 0px 12px;

    h2 {
      // margin: 0;
      font-size: 28px;
      line-height: 28px;

      padding-bottom: 36px;
    }
    // h2:after {
    //   content: ':';
    // }
    .swiper {
      // width: 100%;
      // position: relative;
      .swiper-slide {
        .card {
          // border: 1px solid rgba(217, 217, 217, 1);
          border-radius: 28px;
          padding: 0px 18px 10px 18px;
          // display: flex;
          // flex-direction: column;
          height: 184px;
          .top {
            // color: var(--text-color);
            font-size: 16px;
            line-height: 19px;
            padding-bottom: 20px;
          }
          .bottom {
            // color: var(--text-color);
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
      .swiper-pagination {
        position: absolute;
        top: 164px;
        left: -32%;
      }

      .swiper-pagination-bullet {
        background-color: transparent;
        border: 2px solid black;
      }

      .swiper-pagination-bullet-active {
        background-color: black;
        border: 2px solid black;
      }
    }
  }
}
