@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

.ubuntu-light {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 300;
  font-style: normal;
}

.ubuntu-regular {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.ubuntu-medium {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.ubuntu-bold {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-style: normal;
}

:root {
  --primary-color: rgba(3, 4, 8, 1);
  --secondary-color: #653cf4;
  --text-color: rgba(255, 255, 255, 1);
}

body {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-image: url('../img/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100vw;
  height: 100vh;
}

.container {
  max-width: 1440px;
  height: 100%;
  margin: 0 auto;
  min-width: 320px;
}

.notification {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(151, 43, 43);
  color: var(--text-color);
  padding: 15px 30px;
  border-radius: 5px;
  display: none;
  z-index: 9;
  white-space: nowrap;
}

.success {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--primary-color);
  color: var(--text-color);
  padding: 25px 30px;
  font-size: 22px;
  border: 1px solid var(--text-color);
  border-radius: 15px;
  display: none;
  z-index: 9;
  white-space: nowrap;
}

.footer {
  background-color: #653cf4;
  padding: 36px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 36px;
  border-top-right-radius: 28px;
  border-top-left-radius: 28px;
  margin-top: 90px;
  padding-bottom: 36px;
}

.nowrap {
  white-space: nowrap;
}

// * {
//   outline: 1px solid red;
// }

@media (max-width: 500px) {
  body {
    background-image: url('../img/back-mob.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}
