header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 36px 20px 36px;
  img {
    width: 220px;
    height: 38px;
  }

  .contacts {
    display: flex;
    align-items: center;
    gap: 40px;

    .icons {
      display: flex;
      align-items: center;
      gap: 20px;

      a {
        width: 32px;
        height: 32px;
      }

      a:hover {
        transform: translateY(-4px);
      }

      .tg {
        background-image: url('./../img/tg.svg');
      }

      .vk {
        background-image: url('./../img/vk.svg');
        border-radius: 18px;
      }
    }
  }
}

@media (max-width: 900px) {
  header {
    padding: 14px 16px 20px 16px;
    img {
      width: 141.568px;
      height: 24px;
    }
  }
}
