.price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 36px;
  padding-bottom: 32px;
  background-color: #fff;
  border-radius: 28px;

  .texts {
    h2 {
      margin: 0;
      padding: 0;
      color: #030408;
      font-size: 36px;
      line-height: 39px;
    }
    p {
      margin: 0;
      padding: 0;
      margin-top: 8px;
      color: #030408;
      font-size: 20px;
      line-height: 28px;
    }
  }

  a {
    display: flex;
    padding: 20px 20px 20px 28px;
    align-items: center;
    gap: 16px;
    border-radius: 28px;
    background: #030408;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    .arrow {
      width: 40px;
      height: 40px;
      background-image: url('./../img/arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
  a:hover {
    transform: scale(1.1);
  }
}

@media (max-width: 940px) {
  .footer {
    margin-top: 80px;
    padding: 16px;
  }
  .price {
    flex-direction: column;
    padding: 20px;
    padding-top: 36px;
    padding-bottom: 36px;

    .texts {
      h2 {
        text-align: center;
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 12px;
        font-size: 28px;
        line-height: 33px;
      }
      p {
        text-align: center;
        padding-bottom: 46px;
      }
    }

    a {
      padding: 20px 20px 20px 28px;
      width: 250px;
    }
  }
}

@media (max-width: 558px) {
  span::after {
    content: '\A';
    white-space: pre;
  }
}

@media (max-width: 558px) {
  .footer {
    margin-top: 60px;
    padding: 12px;
  }
  .price {
    padding: 20px;
    padding-top: 36px;
    padding-bottom: 36px;
    .texts {
      h2 {
        padding-left: 2px;
        padding-right: 2px;
        padding-bottom: 12px;
        font-size: 25px;
        line-height: 30px;
      }
      p {
        padding-bottom: 22px;
        font-size: 14px;
        line-height: 18px;
        width: 240px;
      }
    }
    a {
      gap: 16px;
      border-radius: 28px;
      padding: 4px 4px 4px 16px;
      font-size: 16px;
      line-height: 36px;
      color: #fff;
      width: 210px;
      height: 40px;
      .arrow {
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}
