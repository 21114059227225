.contact {
  background-color: var(--primary-color);
  border-radius: 28px;

  display: flex;
  justify-content: space-between;
  padding: 100px 84px 80px 80px;

  .title-mob {
    display: none;
  }

  p {
    margin: 0;
    padding: 0;
  }
  a {
    margin: 0;
    padding: 0;
  }
  .left {
    display: flex;
    flex-direction: column;
    padding-right: 14px;
    .title-desk {
      margin: 0;
      padding: 0;
      color: var(--text-color);
      font-size: 36px;
      line-height: 43px;
      max-width: 450px;
    }

    .mail-tel {
      padding-top: 110px;
    }

    .tels {
      display: flex;
      flex-direction: column;
      padding-top: 40px;
    }

    .mail {
      color: var(--text-color);
      font-size: 28px;
      line-height: 36px;
      margin-top: 110px;
      margin-bottom: 40px;
    }

    .tel {
      color: var(--text-color);
      font-size: 24px;
      line-height: 31px;
      margin-bottom: 14px;
    }

    .copyright-mob {
      display: none;
    }

    .copyright-desk {
      margin-top: 26px;
      color: var(--text-color);
      font-size: 16px;
      line-height: 21px;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    width: 602px;
    p {
      color: var(--text-color);
      font-size: 27px;
      line-height: 27px;
      margin-bottom: 28px;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .top {
        display: flex;
        gap: 24px;
        justify-content: space-between;

        input {
          flex: 1;
          color: var(--text-color);
          border: 1px solid rgba(255, 255, 255, 0.5);
          border-radius: 20px;
          padding: 18px;
          background: transparent;
          font-size: 20px;
          min-width: 220px;
        }

        input::placeholder {
          opacity: 50%;
        }
      }

      .email {
        flex: 1;
        font-size: 20px;
        color: var(--text-color);
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 20px;
        padding: 18px;
        background: transparent;
      }

      .email::placeholder {
        opacity: 50%;
      }

      textarea {
        flex: 1;
        font-size: 20px;
        color: var(--text-color);
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 20px;
        padding: 18px;
        background: transparent;
        min-height: 140px;
      }

      textarea::placeholder {
        opacity: 50%;
      }

      .bottom {
        display: flex;
        gap: 34px;
        position: relative;

        button {
          border-radius: 36px;
          color: var(--primary-color);
          font-size: 20px;
          width: 208px;
          height: 64px;
          transition: transform 0.3s ease;
          background-color: var(--text-color);
        }

        button:hover {
          transform: scale(1.1);
        }
        p {
          font-size: 14px;
          line-height: 16px;
          color: var(--text-color);
          padding-top: 16px;
        }
        a {
          text-decoration: none;
          position: relative;
          display: inline-block;
          color: var(--text-color);
        }
        a::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0px;
          height: 1px;
          background-color: currentColor;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .contact {
    flex-direction: column-reverse;
    align-items: center;
    padding: 30px 0px 30px 0px;

    .title-desk {
      display: none;
    }

    .left {
      .mail-tel {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0px;
      }

      .tels {
        padding-top: 0;
      }

      .mail {
        margin-top: 0px;
      }
    }

    .right {
      .title-mob {
        display: block;
        margin: 0;
        padding: 0;
        color: var(--text-color);
        font-size: 36px;
        line-height: 43px;
        max-width: 811px;
        padding-bottom: 20px;
      }
    }
  }
}

@media (max-width: 660px) {
  .footer {
    gap: 22px;
  }
  .contact {
    padding: 10px 20px 0px 30px;

    .left {
      padding-right: 0px;

      .mail-tel {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 22px;
        padding-left: 0px;
        padding-right: 0px;
      }

      .tels {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 0;
      }

      .title-desk {
        font-size: 36px;
        line-height: 43px;
        max-width: 450px;
      }

      .mail {
        font-size: 12px;
        line-height: 15px;
        margin-top: 0px;
        margin-bottom: 0px;
      }

      .tel {
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 0px;
      }

      .copyright-mob {
        display: flex;
        margin-top: 26px;
        color: var(--text-color);
        font-size: 10px;
        line-height: 14px;
        padding-bottom: 30px;
      }

      .copyright-desk {
        display: none;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      width: 320px;

      .title-mob {
        font-size: 28px;
        line-height: 33px;
        max-width: 511px;
        padding-top: 22px;
        padding-bottom: 44px;
      }

      p {
        font-size: 27px;
        line-height: 27px;
        margin-bottom: 24px;
      }

      form {
        gap: 12px;
        .top {
          flex-direction: column;
          gap: 12px;

          input {
            border-radius: 16px;
            padding: 10px 18px 10px 18px;
            font-size: 16px;
            min-width: 220px;
          }
        }

        .email {
          font-size: 16px;
          border-radius: 16px;
          padding: 10px 18px 10px 18px;
        }

        textarea {
          display: none;
        }

        .bottom {
          gap: 6px;
          padding-top: 10px;

          button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 24px;
            font-size: 16px;
            width: 134px;
            height: 40px;
            padding: 6px 6px 6px 12px;
            background-color: var(--text-color);
            font-family: 'Ubuntu', sans-serif;
            font-weight: 400;
            font-style: normal;

            .arrow {
              width: 24px;
              height: 24px;

              background-image: url('./../img/arrow.svg');
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
            }
          }
          button:hover {
            transform: none;
          }

          p {
            font-size: 9px;
            line-height: 9px;
            padding-top: 10px;
          }
        }
      }
    }
  }
}

.error-border {
  border: 2px solid rgb(151, 28, 28);
}

@media (max-width: 390px) {
  .footer {
    gap: 12px;
  }
  .contact {
    padding: 10px 12px 0px 12px;

    .left {
      padding-right: 0px;

      .mail-tel {
        padding-top: 22px;
        padding-left: 0px;
        padding-right: 0px;
      }

      .tels {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 0;
      }

      .mail {
        font-size: 12px;
        line-height: 15px;
        margin-top: 0px;
        margin-bottom: 0px;
      }

      .tel {
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 0px;
      }

      .copyright-mob {
        width: 256px;
      }
    }

    .right {
      width: 270px;

      .title-mob {
        font-size: 20px;
        line-height: 24px;
        width: 246px;
        text-align: center;
        align-self: center;

        padding-top: 12px;
        padding-bottom: 38px;
      }

      p {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 24px;
      }

      form {
        gap: 8px;

        #formCaptcha {
          width: 260px;

          overflow: hidden;
        }
        .top {
          flex-direction: column;
          gap: 8px;

          input {
            border-radius: 16px;
            padding: 10px 18px 10px 12px;
            font-size: 14px;
            min-width: 226px;
          }
        }

        .email {
          font-size: 14px;
          border-radius: 16px;
          padding: 10px 18px 10px 12px;
          min-width: 226px;
        }

        .bottom {
          gap: 6px;
          padding-top: 4px;

          button {
            font-size: 12px;
            width: 118px;
            height: 34px;
            padding: 4px 4px 4px 12px;
          }

          p {
            font-size: 8px;
            line-height: 9px;
            padding-top: 8px;
          }
        }
      }
    }
  }
}
