.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(60, 59, 59, 0.5);

  .modal-content {
    background-color: var(--primary-color);

    border-radius: 28px;
    margin: 15% auto;
    max-width: 600px;
    padding: 40px;
    width: 80%;
  }

  .modal-header {
    display: flex;
    position: relative;
    h3 {
      margin: 0;
      padding: 0;
      color: var(--text-color);
      font-size: 24px;
      line-height: 28px;
      max-width: 400px;
    }

    .close {
      width: 36px;
      height: 36px;
      background-image: url('./../img/close.svg');
      position: absolute;
      top: 0;
      right: 0;
    }

    .close:hover,
    .close:focus {
      opacity: 50%;
      cursor: pointer;
    }
  }
  .contact-mob {
    display: none;
  }

  form {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .top {
      display: flex;
      gap: 24px;
      justify-content: space-between;

      input {
        flex: 1;
        color: var(--text-color);
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 20px;
        padding: 18px;
        background: transparent;
        font-size: 20px;
        min-width: 220px;
      }

      input::placeholder {
        opacity: 50%;
      }
    }

    .email {
      flex: 1;
      font-size: 20px;
      color: var(--text-color);
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 20px;
      padding: 18px;
      background: transparent;
    }

    .email::placeholder {
      opacity: 50%;
    }

    textarea {
      flex: 1;
      font-size: 20px;
      color: var(--text-color);
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 20px;
      padding: 18px;
      background: transparent;
      min-height: 140px;
    }

    textarea::placeholder {
      opacity: 50%;
    }

    .bottom {
      display: flex;
      position: relative;
      gap: 20px;
      padding-top: 0px;

      button {
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 24px;
        font-size: 16px;
        width: 142px;
        height: 40px;
        padding: 6px 6px 6px 12px;
        background-color: var(--text-color);
        color: var(--primary-color);
        transition: transform 0.3s ease;

        .arrow {
          width: 24px;
          height: 24px;

          background-image: url('./../img/arrow.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      }
      button:hover {
        transform: scale(1.1);
      }

      p {
        margin: 0;
        color: var(--text-color);
        font-size: 13px;
        line-height: 14px;
        padding-top: 6px;
      }

      a {
        position: relative;
        display: inline-block;
        color: var(--text-color);
      }
      a::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0px;
        height: 1px;
        background-color: currentColor;
      }
    }

    .error-border {
      border: 2px solid rgb(151, 28, 28);
    }
  }
}

@media (max-width: 680px) {
  .modal {
    .modal-content {
      width: 286px;
      padding: 20px;
    }

    .modal-header {
      h3 {
        width: 226px;
      }

      .close {
        width: 36px;
        height: 36px;
        background-image: url('./../img/close.svg');
        position: absolute;
        top: 0;
        right: 0;
      }

      .close:hover,
      .close:focus {
        opacity: 50%;
        cursor: pointer;
      }
    }

    .contact-mob {
      display: block;
      color: var(--text-color);
      font-size: 27px;
      line-height: 27px;
      margin-top: 40px;
    }
    form {
      padding-top: 4px;

      gap: 10px;
      .top {
        gap: 10px;
        flex-direction: column;

        input {
          border-radius: 16px;
          padding: 10px 18px 10px 18px;
          font-size: 16px;
          min-width: 220px;
        }
      }

      .email {
        font-size: 16px;
        border-radius: 16px;
        padding: 10px 18px 10px 18px;
      }

      textarea {
        display: none;
      }

      .bottom {
        padding-top: 4px;
        gap: 6px;

        button {
          justify-content: space-between;
          gap: 0px;
          width: 134px;
          padding: 6px 6px 6px 10px;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 400;
          font-style: normal;
        }

        button:hover {
          transform: none;
        }

        p {
          font-size: 9px;
          line-height: 9px;
        }
      }
    }
  }
}

@media (max-width: 390px) {
  .modal {
    .modal-content {
      width: 270px;
      padding: 16px;
      padding-right: 12px;
      padding-left: 12px;
    }

    .modal-header {
      h3 {
        width: 226px;
      }
    }

    form {
      #captcha {
        width: 260px;
        overflow: hidden;
      }
      .bottom {
        button {
          font-size: 14px;
        }
      }
    }
  }
}
