.syllabus {
  display: flex;
  p,
  h2,
  span,
  h3 {
    margin: 0;
    padding: 0;
  }
  display: flex;
  gap: 20px;
  padding-top: 20px;
  padding-right: 27px;
  padding-left: 36px;
  .program-container {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
  .program {
    display: flex;
    padding: 28px;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    border-radius: 24px;
    background: #653cf4;
    flex: 1;
    h3 {
      color: #fff;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 112%;
    }
    .content {
      display: flex;
      align-items: flex-start;
      gap: 19.5px;
      .times {
        display: flex;
        width: 166px;
        flex-direction: column;
        align-items: flex-end;
        gap: 9.75px;
        span {
          align-self: stretch;
          color: #fff;
          font-size: 28px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }
      }
      .descriptions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 9.75px;
        span {
          color: #fff;
          font-size: 28px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
        }
      }
    }
  }
  .learn {
    display: flex;
    width: 558px;
    box-sizing: border-box;
    padding: 28px;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;

    border-radius: 24px;
    background: #030408;
    h3 {
      color: #fff;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 112%;
    }
    ol {
      width: 459px;
      margin-top: 0;
      margin-bottom: 0;
      li {
        color: #fff;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 19.5px;
      }
    }
  }
}

@media (max-width: 1170px) {
  .syllabus {
    display: flex;
    gap: 20px;
    padding-top: 20px;
    padding-right: 27px;
    padding-left: 36px;
    .program {
      display: flex;
      padding: 28px;
      flex-direction: column;
      align-items: flex-start;
      gap: 28px;

      border-radius: 24px;
      background: #653cf4;
      flex: 1;
      min-width: 300px;
      h3 {
        color: #fff;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 112%;
      }
      .content {
        display: flex;
        align-items: flex-start;
        gap: 1px;
        .times {
          display: flex;
          width: 100px;
          flex-direction: column;
          align-items: flex-end;
          gap: 9.75px;
          span {
            align-self: stretch;
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
          }
        }
        .descriptions {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 9.75px;
          span {
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
          }
        }
      }
    }
    .learn {
      display: flex;
      width: 470px;
      box-sizing: border-box;
      padding: 28px;
      flex-direction: column;
      align-items: flex-start;
      gap: 28px;

      border-radius: 24px;
      background: #030408;
      h3 {
        color: #fff;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 112%;
      }
      ol {
        width: 359px;
        margin-top: 0;
        margin-bottom: 0;
        li {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          margin-bottom: 19.5px;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .syllabus {
    flex-direction: column;
    gap: 20px;
    padding-top: 16px;
    padding-right: 16px;
    padding-left: 16px;
    .program {
      //   display: flex;
      padding: 22px 20px;
      //   flex-direction: column;
      //   align-items: flex-start;
      gap: 22px;
      //   flex-shrink: 0;
      //   border-radius: 24px;
      //   background: #653cf4;
      //   flex: 1;
      //   h3 {
      //     color: #fff;
      //     font-size: 40px;
      //     font-style: normal;
      //     font-weight: 500;
      //     line-height: 112%;
      //   }
      .content {
        //     display: flex;
        //     align-items: flex-start;
        gap: 10px;
        //     .times {
        //       display: flex;
        //       width: 166px;
        //       flex-direction: column;
        //       align-items: flex-end;
        //       gap: 9.75px;
        //       span {
        //         align-self: stretch;
        //         color: #fff;
        //         font-size: 28px;
        //         font-style: normal;
        //         font-weight: 500;
        //         line-height: 140%;
        //       }
        //     }
        //     .descriptions {
        //       display: flex;
        //       flex-direction: column;
        //       align-items: flex-start;
        //       gap: 9.75px;
        //       span {
        //         color: #fff;
        //         font-size: 28px;
        //         font-style: normal;
        //         font-weight: 400;
        //         line-height: 140%;
        //       }
        //     }
      }
    }
    .learn {
      //   display: flex;
      width: 100%;
      max-width: 880px;
      min-width: 288px;
      //   box-sizing: border-box;
      padding: 0px;
      margin-top: 18px;
      //   flex-direction: column;
      //   align-items: flex-start;
      gap: 18px;
      //   flex-shrink: 0;
      //   border-radius: 24px;
      background: transparent;
      h3 {
        color: #030408;
        //     color: #fff;
        //     font-size: 40px;
        //     font-style: normal;
        //     font-weight: 500;
        //     line-height: 112%;
      }
      ol {
        width: 100%;
        max-width: 308px;
        padding-right: 28px;
        //     margin-top: 0;
        //     margin-bottom: 0;
        // margin-left: 0px;
        padding-left: 24px;
        li {
          color: #030408;
          //       color: #fff;
          //       font-size: 24px;
          //       font-style: normal;
          //       font-weight: 400;
          //       line-height: 120%;
          margin-bottom: 14px;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .syllabus {
    .learn {
      ol {
        max-width: 280px;
      }
    }
  }
}

@media (max-width: 385px) {
  .syllabus {
    .program-container {
      width: 100%;
    }
    .program {
      //   display: flex;
      padding: 22px 20px;
      padding-right: 0px;
      //   flex-direction: column;
      //   align-items: flex-start;
      gap: 22px;
      //   flex-shrink: 0;
      //   border-radius: 24px;
      //   background: #653cf4;
      //   flex: 1;
      min-width: 200px;
      h3 {
        //     color: #fff;
        //     font-size: 40px;
        //     font-style: normal;
        //     font-weight: 500;
        //     line-height: 112%;
        font-size: 28px;
      }
      .content {
        //     display: flex;
        //     align-items: flex-start;
        width: 260px;
        gap: 10px;
        .times {
          //       display: flex;
          width: 80px;
          //       flex-direction: column;
          //       align-items: flex-end;
          //       gap: 9.75px;
          span {
            //         align-self: stretch;
            //         color: #fff;
            font-size: 14px;
            //         font-style: normal;
            //         font-weight: 500;
            //         line-height: 140%;
          }
        }
        .descriptions {
          //       display: flex;
          //       flex-direction: column;
          //       align-items: flex-start;
          //       gap: 9.75px;
          span {
            //         color: #fff;
            font-size: 14px;
            //         font-style: normal;
            //         font-weight: 400;
            //         line-height: 140%;
          }
        }
      }
    }
    .learn {
      //   display: flex;
      width: 100%;
      max-width: 880px;
      min-width: 200px;
      //   box-sizing: border-box;
      padding: 0px;
      margin-top: 18px;
      //   flex-direction: column;
      //   align-items: flex-start;
      gap: 18px;
      //   flex-shrink: 0;
      //   border-radius: 24px;
      background: transparent;
      h3 {
        color: #030408;
        //     color: #fff;
        font-size: 28px;
        //     font-style: normal;
        //     font-weight: 500;
        //     line-height: 112%;
      }
      ol {
        width: 100%;

        padding-right: 0px;
        //     margin-top: 0;
        //     margin-bottom: 0;
        // margin-left: 0px;
        padding-left: 24px;
        li {
          color: #030408;
          //       color: #fff;
          font-size: 14px;
          //       font-style: normal;
          //       font-weight: 400;
          //       line-height: 120%;
          margin-bottom: 14px;
        }
      }
    }
  }
}
