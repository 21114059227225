.suits-desk {
  width: 100%;
  height: 110%;
  margin-top: 20px;
  background: url('./../img/Subtract.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;
  height: 688px;

  .back {
    background-color: #030408;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 80%;
    top: 20%;
    border-radius: 30px;
  }

  .content {
    position: relative;
    z-index: 3;
    display: flex;
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    gap: 20px;
    height: 100%;

    .main {
      flex: 1;
      margin-left: 36px;
      max-width: 443px;
      padding-top: 22px;
      margin-bottom: 40px;

      h2 {
        color: #030408;
        font-size: 52px;
        line-height: 58px;
        margin: 0;
        padding: 0;
      }

      .card {
        margin-top: 50px;
        border: 1px solid #fff;
        border-radius: 26px;
        padding: 18px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 480px;

        .top {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          p {
            margin: 0;
            padding: 0;
            font-size: 32px;
            line-height: 38px;
            color: #fff;
            max-width: 300px;
          }

          .arrow {
            width: 40px;
            height: 40px;
            background-image: url('./../img/arrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          }
        }

        .bottom {
          margin: 0;
          padding: 0;
          color: #fff;
          font-size: 20px;
          line-height: 26px;
        }
      }
    }

    .cards {
      flex: 2;
      padding-top: 34px;
      padding-right: 36px;
      margin-bottom: 40px;
      display: flex;
      gap: 20px 20px;
      flex-wrap: wrap;
      justify-content: space-between;

      .card {
        flex: 1 1 calc(50% - 20px);
        box-sizing: border-box;
        border: 1px solid #fff;
        border-radius: 26px;
        padding: 18px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 298px;

        .top {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          p {
            margin: 0;
            padding: 0;
            font-size: 32px;
            line-height: 38px;
            color: #fff;
            max-width: 320px;
          }

          .arrow {
            width: 40px;
            height: 40px;
            background-image: url('./../img/arrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          }
        }

        .bottom {
          margin: 0;
          padding: 0;
          color: #fff;
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
  }
}

.suits-mob {
  display: none;
}

@media (max-width: 1340px) {
  .suits-desk {
    .content {
      .main {
        padding-top: 10px;
        h2 {
          font-size: 42px;
        }

        .card {
          .top {
            p {
              max-width: 280px;
            }
          }
        }
      }

      .cards {
        .card {
          .top {
            p {
              max-width: 230px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1165px) {
  .suits-desk {
    .content {
      .main {
        padding-top: 10px;
        h2 {
          font-size: 38px;
        }

        .card {
          .top {
            p {
              max-width: 230px;
              font-size: 26px;
            }
          }
        }
      }

      .cards {
        .card {
          .top {
            p {
              max-width: 210px;

              font-size: 26px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1025px) {
  .suits-desk {
    display: none;
  }

  .suits-mob {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    padding-left: 16px;
    padding-right: 16px;

    h2 {
      color: #030408;
      font-size: 36px;
      line-height: 40px;
      margin: 0;
      padding: 0;
      padding-bottom: 30px;
    }

    .card {
      background-color: #030408;
      border-radius: 26px;
      padding: 16px 20px 16px 20px;
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          margin: 0;
          padding: 0;
          font-size: 24px;
          line-height: 28px;
          color: #fff;
        }

        .title {
          padding-bottom: 50px;
        }

        .plus {
          width: 40px;
          height: 40px;
          background-image: url('./../img/plus.svg');
        }
      }

      .bottom {
        margin: 0;
        padding: 0;
        color: #fff;
        font-size: 20px;
        line-height: 26px;
      }

      .bottom-plus {
        max-height: 0;
        overflow: hidden;
        transition: max-height 1s ease, padding 0.5s ease;
      }

      .expanded {
        max-height: 200px;
        padding-top: 40px;
      }

      .hidden {
        display: none;
      }
    }
  }
}

@media (max-width: 400px) {
  .suits-mob {
    .card {
      .top {
        p {
          max-width: 210px;
        }
        p:first-child {
          max-width: 300px;
        }
      }
    }
  }
}

@media (max-width: 390px) {
  .suits-mob {
    // margin-top: 0px;
    padding-left: 16px;
    padding-right: 16px;
    h2 {
      // font-size: 28px;
      line-height: 31px;
      padding-bottom: 20px;
    }
    .card {
      border-radius: 28px;
      padding: 18px 20px 18px 20px;
      margin-bottom: 8px;
      .top {
        p {
          // font-size: 20px;
          line-height: 24px;
        }
        .title {
          padding-bottom: 44px;
        }
      }
      .title-top {
        width: 210px;
      }
      .bottom {
        font-size: 12px;
        line-height: 15px;
        width: 183px;
      }
      .expanded {
        max-height: 200px;
        padding-top: 40px;
      }
      .hidden {
        display: none;
      }
    }
  }
}
